<template>
  <van-empty :image="require('@/assets/unverify.png')" description="该页面需要账号审核后才可访问，魔脉线上平台申请请联系助叶老师" />
  <div class="p2">
    <van-button block round type="primary" to="/">
      返回首页
    </van-button>
  </div>
</template>
<script>
export default {
}
</script>
<style lang="less" scoped>
  .van-empty {
    padding: 20vh 0 0;
    font-family:"Times New Roman", Times, serif;
  }
  :deep(.van-empty__description) {
    color: #555;
    font-size: 14px;
    text-align: center;
    line-height: 40px;
  }
</style>
